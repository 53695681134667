import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { ClaimService } from 'FNOLAdminWeb/app/shared/claimservice';
import { SendReportOfLossService } from 'FNOLAdminWeb/app/shared/sendreportofloss.service';

@Component({
  selector: 'app-claimsidebar-component',
  templateUrl: './claimsidebar.component.html',
  styleUrls: ['claimsidebar.component.css']
})
export class ClaimSidebarComponent {
  @Input() claimScreens: any;
  @Input() claimId: any;
  @Output() screenId = new EventEmitter();
  @Output() sentReportOfLoss = new EventEmitter();
  currentScreen:any;
  constructor(private _location: Location, private claimService: ClaimService, private sendReportOfLossService:SendReportOfLossService) {

  }

  ngOnInit() {
    //console.log('Claim Screens: ' + JSON.stringify(this.claimScreens));
  }

  displayScreen(id, desc) {
    this.currentScreen = desc; 
    this.screenId.emit(id);
  }

  returnToSearchResult() {
    this._location.back();
  }

  sendReportOfLoss(){
    this.sendReportOfLossService.send(this.claimId).subscribe(() =>{
      this.sentReportOfLoss.emit('Report Of Loss was sent.');
    });
  }

  openDocForReview(){
    this.claimService.getClaimPdf(this.claimId).subscribe(data => {
      // let x = window.open();
      // let iframe = x.document.createElement('iframe');
      // iframe.width = '100%';
      // iframe.height = '100%';
      // iframe.src = "data:application/pdf;base64, " + (<PDFResponse>data).result;
      // x.document.body.appendChild(iframe);

      this.printPreview((<PDFResponse>data).result);
  });
  }

  printPreview(data){
    var type = 'application/pdf';
    let blob = null;
    const blobURL = URL.createObjectURL(this.pdfBlobConversion(data, 'application/pdf'));
    const theWindow = window.open(blobURL);
    const theDoc = theWindow.document;
    const theScript = document.createElement('script');
    function injectThis() {
        window.print();
    }
    theScript.innerHTML = 'window.onload = ${injectThis.toString()};';
    theDoc.body.appendChild(theScript);
}

pdfBlobConversion(b64Data, contentType) {
  contentType = contentType || '';
  var sliceSize = 512;
  b64Data = b64Data.replace(/^[^,]+,/, '');
  b64Data = b64Data.replace(/\s/g, '');
  var byteCharacters = window.atob(b64Data);
  var byteArrays = [];

  for ( var offset = 0; offset < byteCharacters.length; offset = offset + sliceSize ) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

}

class PDFResponse{
  result:string;
  errorMessage:string;
  timeGenerated:string;
}
