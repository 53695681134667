import { Component } from '@angular/core';
import { SearchService } from '../shared/searchservice';
import { Router } from '@angular/router';
import { RouterLinkRendererComponent } from './routerlinkrenderer.component';

@Component({
  selector: 'app-searchresult-component',
  templateUrl: './searchresult.component.html',
  styleUrls: ['searchresult.component.css']
})
export class SearchResultComponent {
  serviceResult: any;
  rowData: any = [];
  columnDefs: any = [
    {
      headerName: 'Reference Number', field: 'claimId', /*cellRenderer: (claimId) =>
        `<a [routerLink]="/claim/${claimId.value}" >Details</a>`*/

      cellRendererFramework: RouterLinkRendererComponent, sortable: true
    },
    { headerName: 'Claim Number', field: 'referenceNumber', cellRenderer : function(params){
      if(params.value == "0" || params.value == null)
      return '';
      else return params.value;
      }, sortable: true },
    { headerName: 'Policy Number', field: 'policyNumber', tooltip: (params) => params.value, sortable: true },
    { headerName: 'Insured Name', field: 'insuredName', tooltip: (params) => params.value, sortable: true },
    { headerName: 'Contact Name', field: 'contactName', sortable: true },
    { headerName: 'Status', field: 'status', tooltip: (params) => params.value, sortable: true },
    { headerName: 'Received Date', field: 'receivedDate', sortable: true },
    { headerName: 'Adjuster', field: 'adjuster', tooltip: (params) => params.value, sortable: true }
  ];
  constructor(private searchService: SearchService, private router: Router) {
    if (this.router.getCurrentNavigation().extras.state !== undefined) {
      this.rowData = this.router.getCurrentNavigation().extras.state.searchResult.result;
    }
    else {
      let lastSearchResult = sessionStorage.getItem('lastSearchResult');
      if (lastSearchResult !== null) {
        this.rowData = JSON.parse(lastSearchResult).result;
      }
      else {
        this.rowData = [];
      }
    }
  }

  ngOnInit() {
  }
  
  onGridReady(params) {
    params.api.sizeColumnsToFit();
  }

}
