<form [formGroup]="formdata"  (ngSubmit)="submitForm(formdata.value)">
    <div class="container">
        <div class="row card bg-light">
            <h4 id="h4ScreenDesc" class="h4 card-header banner">GETTING STARTED</h4>
            <div class="row">
                <div class="container pt-3">
                <div class="col-md-12 form-group">
                    <p>
                        <b>
                            Please Enter Policy Number and Named Insured Zip Code in below fields:
                        </b>
                    </p>
                </div>
                <div class="col-md-12 form-group">
                    <label for="txtPolicyNumber">Policy Number:</label>
                </div>
                <div class="col-md-6 form-group">
                    <input required name="policyNumber" class="form-control form-control-sm" id="txtPolicyNumber" type="text" formControlName = "policyNumber" />
                    <div *ngIf="formdata.controls.policyNumber.invalid">
                        <em class="text-danger" *ngIf="formdata.controls.policyNumber.errors?.required">Required</em>
                    </div>
                </div>
                <div class="col-md-12 form-group">
                    <label for="txtZipCode">Insured Zip Code:</label>
                </div> 
                    <div class="col-md-6 form-group">
                    <input required name="zipCode" class="form-control form-control-sm" id="txtZipCode" type="text" formControlName = "zipCode" />
                    <div *ngIf="formdata.controls.zipCode.invalid">
                        <em class="text-danger" *ngIf="formdata.controls.zipCode.errors?.required">Required</em>
                    </div>
                    <div *ngIf="formdata.controls.zipCode.invalid">
                        <em class="text-danger" *ngIf="formdata.controls.zipCode.touched && formdata.controls.zipCode.errors?.whitespace">Please enter without spaces</em>
                    </div>
                </div>  
            </div>
            </div>
            <div class="card-footer">
                <button id="btnContinue" type="submit" class="btn btn-labeled btn-colour-1 btn-sm">
                    <span class="btn-label">
                        <i class="fa fa-save"></i> |
                        <i class="fa fa-long-arrow-right"></i>
                    </span>Continue
                </button>            
            </div>
        </div>
    </div>
</form>
<jw-modal id="custom-modal-1">
    <div class="card text-center">
        <h4 class="card-header">{{modalHeader}}</h4>
      <div class="card-body">
        <p class="card-title">{{modalText}}</p>
      </div>
        <div class="card-footer">
          <button class="btn btn-primary" (click)="closeModal()">Close</button>
          </div>
    </div>
  </jw-modal>